import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// get translated file list in the Translations folder
const translations = require.context('./Translations', true, /\.json$/);
const resources = {};
translations.keys().forEach((key) => {
  const language = key.split('/')[1].split('.')[0];
  resources[language] = { translation: translations(key) };
});

i18n
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources,
  });
  
