import React from 'react';
import { Box, Grid } from '@mui/material';
import RadioComponent from './Radio';
import Frame from './Frame';
import '../../../fonts.css';
import '../Home.css';
function SelectorFrame({ isMobile }) {

  return isMobile ? (
    <Box
      sx={{
        borderRadius: '16px',
        bgcolor: '#E8E6E3',
        gap: '16px',
      }}
    >
      <Grid item>
        <RadioComponent isMobile={isMobile} />
      </Grid>
      <Grid item>
        <Frame isMobile={isMobile} />
      </Grid>
    </Box>
  ) : (
    <div className="selector-frame">
      <div>
        <RadioComponent />
      </div>
      <div>
        <Frame />
      </div>
    </div>
  );
}
export default SelectorFrame;
