import React from 'react';
const Button = ({ name, style, onClick, id }) => {
  const handleButtonClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <button style={style} onClick={handleButtonClick} id={id}>
      {name}
    </button>
  );
};
export default Button;
