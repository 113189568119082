import React from 'react';

const styles = {
    warning: {
        position: 'relative',
        zIndex: 999999,
        backgroundColor: '#FFEB3B',
        textAlign: 'center',
        color: '#D32F2F',
        margin: '0 auto',
        width: '100%',
        padding: '10px 0px',
    },
};

const Warning = () => {
  return (
    <p style={styles.warning}>This is a software testing site NOT intended for end user usage to retrieve information about products.</p>
  );
};

export default Warning;