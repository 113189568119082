import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider, createTheme } from '@mui/material';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";

// const theme = createTheme({
//   palette: {
//     primary: {
//       main:'#F9F8F7', // Main color for primary elements
//     },
//     secondary: {
//       main: '#EB1700', // Main color for secondary elements
//     }
//   } 
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <ThemeProvider theme={theme}> */}
      <App />
    {/* </ThemeProvider> */}

  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
