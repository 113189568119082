import React from 'react';
import { useTranslation } from 'react-i18next';
import CountryList from '../../reusableComponents/CountryList';
import LanguageList from '../../reusableComponents/LanguageList';
import '../../../fonts.css';
import { Grid } from '@mui/material';
function Frame({ isMobile, onCountryChange }) {
  const { t } = useTranslation();

  return isMobile ? (
    <Grid container direction={'row'} justifyContent={'space-between'} sx={{ gap: '8px' }}>
      <Grid item sx={{ width: { xs: '100%', md: 'auto' } }}>
        <Grid container direction={'row'} sx={{ padding: '16px' }}>
          <Grid item>
            <img src="images/Language.png" width={'30px'} height={'18px'} />
          </Grid>
          <Grid item>
            <LanguageList />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction={'row'} sx={{ gap: '8px', padding: '0 16px 16px 16px' }}>
          <Grid item>
            <img src="images/Country.png" width={'24.24px'} height={'21.12px'} />
          </Grid>
          <Grid item sx={{ marginRight: '8px' }}>
            <CountryList />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid container direction={'row'}>
      <Grid item>
        <img src="svg/lang-Vector.svg" width={'30px'} height={'18px'} style={{ marginTop: '16px' }} />
      </Grid>
      <Grid item sx={{ marginTop: '13px' }}>
        <LanguageList />
      </Grid>
      <Grid item>
        <Grid container direction={'row'}>
          <Grid item>
            <img src="svg/country-Vector.svg" width={'24.24px'} height={'21.12px'} style={{ marginTop: '16px' }} />
          </Grid>
          <Grid item sx={{ marginTop: '13px' }}>
            <CountryList />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default Frame;
