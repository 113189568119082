import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Card, CardContent, Stack } from '@mui/material';
import '../Home.css';
import Button from '../../reusableComponents/Button';
import CustomAlert from '../../reusableComponents/CustomAlertBox';
import { environment } from '../../../environments/environment';

const CustomH2 = styled.h2`
  font-family: 'JohnsonDisplay-Regular', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  margin: 0;
`;
const CustomInput = styled.input`
  font-family: 'JohnsonText-Regular', sans-serif;
  font-weight: 400;
  font-size: 16px;
  height: 40px;
  line-height: 24px;
  border-radius: 8px;
  color: #ffffff;
  background-color: #0000001a;
  border: none;
  padding-left: 16px;
  margin: auto;
  width: 70%;
  max-width: 70%;
`;
const customButton = {
  backgroundColor: 'white',
  color: 'red',
  borderRadius: '8px',
  width: 'auto',
  minWidth: '70px',
  gap: '10px',
  height: '40px',
  border: 'none',
  fontFamily: 'JohnsonText-Regular, sans-serif',
  fontWeight: '700',
  fontSize: '13px',
};
function Search({ isMobile }) {
  const [searchText, setSearchText] = useState('');
  const [result, setResult] = useState({});
  const [showCustomAlert, setShowCustomAlert] = useState(false);
  const [customAlertMessage, setCustomAlertMessage] = useState('');
  const { t } = useTranslation();

  const closeCustomAlert = () => {
    setShowCustomAlert(false);
  };
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (/^[0-9]*$/.test(inputValue) && inputValue.length <= 14) {
      setSearchText(inputValue);
    }
  };

  const navigate = useNavigate();
  const handleSearchClick = () => {
    let id = searchText;
    let href = '';
    /** set gtin to 14 character length if it is either 8, 12, 13 */
    if (id.length == 8 || id.length == 12 || id.length == 13 || id.length == 14) {
      id = id.padStart(14, '0');
      href = `01/${id}`;
    } else {
      setCustomAlertMessage(t('main.GTINSearch.errMessage1')); // Set alert message
      setShowCustomAlert(true); // Show custom alert
      return;
    }
    var gs1DigitalLinkURI = '';
    // gs1DigitalLinkURI = environment.gs1url + '/' + href;
    gs1DigitalLinkURI = window.location.origin + '/' + href;
    // Fetch headers
    let headers = new Headers();
    headers.append('Accept', 'application/linkset+json');

    fetch('/save_activity_data/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        activity_type: 'action',
        additional_data: {
          action: 'search',
          gtin: id,
          gs1DigitalLinkURI: gs1DigitalLinkURI,
        },
      }),
    });
    fetch(gs1DigitalLinkURI, { headers, method: 'HEAD' })
      .then((response) => {
        if (response.redirected) {
          return {
            url: gs1DigitalLinkURI,
            status: 302,
          };
        }
        if (!response.ok) {
          throw new Error(response.statusText);
        }
      })
      .then((obj) => {
        if (obj?.status == 302) {
          window.open(obj.url, '_self');
          return;
        }
        // go to search page
        setResult({
          gtin: id,
          gs1DigitalLinkURI: gs1DigitalLinkURI,
        });
        try {
          // Router.push('/search', result);
          navigate(`/search/01/${id}`, { state: result });
        } catch (error) {
          console.log(error);
        }
      })
      .catch((error) => {
        console.log(error);
        setCustomAlertMessage(t('main.GTINSearch.errMessage2')); // Set alert message
        setShowCustomAlert(true);
      });
  };
  return (
    <Card
      sx={{
        backgroundColor: 'red',
        borderRadius: '16px',
        width: isMobile ? '100%' : '50%',
        margin: '0',
      }}
    >
      <CardContent style={{ padding: '16px' }}>
        <CustomH2>{t('main.searchCard.btnLabel')}</CustomH2>
        <Stack
          direction={'row'}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          alignItems="center"
          justifyContent={'space-between'}
          sx={{ gap: '8px' }}
        >
          <CustomInput
            type="text"
            placeholder={t('GTINDetails.gtinNum.title')}
            value={searchText}
            onChange={handleInputChange}
            className="hover-input"
          />
          <Link onClick={handleSearchClick}>
            <Button style={customButton} name={t('main.searchCard.btnLabel')} />
          </Link>
        </Stack>
      </CardContent>
      {showCustomAlert && <CustomAlert message={customAlertMessage} onClose={closeCustomAlert} />}
    </Card>
  );
}
export default Search;
