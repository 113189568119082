import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Grid } from '@mui/material';
import '../../../fonts.css';
import '../Home.css';
import Typography from '@mui/material/Typography';

function RadioComponent({ isMobile }) {
  let lastHcp = localStorage.getItem('hcp') || 'noHcp';
  const [hcp, setHcp] = useState(lastHcp);
  const { t } = useTranslation();
  const handleOptionChange = (event) => {
    setHcp(event.target.value);
    localStorage.setItem('hcp', event.target.value);
  };
  const controlProps = (item) => ({
    checked: hcp === item,
    onChange: handleOptionChange,
    value: item,
    name: 'health-care-radio-button',
    inputProps: { 'aria-label': item },
  });
  return isMobile ? (
    <RadioGroup value={hcp} onChange={handleOptionChange} sx={{ padding: '16px' }}>
      <FormControlLabel
        value={'noHcp'}
        control={
          <Radio
            {...controlProps('noHcp')}
            sx={{
              color: 'red',
              '&.Mui-checked': {
                color: 'red',
              },
            }}
          />
        }
        label={t('main.radioSelector1')}
      />
      <FormControlLabel
        value={'hcp'}
        control={
          <Radio
            {...controlProps('hcp')}
            sx={{
              marginBottom: '-8px',
              color: 'red',
              '&.Mui-checked': {
                color: 'red',
              },
            }}
          />
        }
        label={t('main.radioSelector2')}
      />
    </RadioGroup>
  ) : (
    <Grid container direction={'row'}>
      <Grid item sx={{ marginTop: '0' }}>
        <RadioGroup row value={hcp} onChange={handleOptionChange}>
          <FormControlLabel
            value={'noHcp'}
            control={
              <Radio
                {...controlProps('noHcp')}
                sx={{
                  color: 'red',
                  '&.Mui-checked': {
                    color: 'red',
                  },
                }}
              />
            }
            label={<Typography variant="body2">{t('main.radioSelector1')}</Typography>}
          />
          <FormControlLabel
            value={'hcp'}
            control={
              <Radio
                {...controlProps('hcp')}
                sx={{
                  color: 'red',
                  '&.Mui-checked': {
                    color: 'red',
                  },
                }}
              />
            }
            label={<Typography variant="body2">{t('main.radioSelector2')}</Typography>}
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );
}
export default RadioComponent;
