import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Stack } from '@mui/material';
import Title from '../Title';
import { Link } from 'react-router-dom';
import '../Home.css';
import '../../../fonts.css';
import { PRIVACY_POLICY_DATA } from '../../../constants';
const StyledBody = styled.p`
  font-family: 'JohnsonText-Regular', sans-serif;
  color: black;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: justify;
`;
const CustomH2 = styled.h2`
  font-family: 'JohnsonDisplay-Regular', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: black;
  border-bottom: 1px solid #ababab;
`;
const CustomList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none outside;
  @media (min-width: 992px) {
    max-width: 173px;
    min-width: 160px;
  }

  > li {
    padding: 12px 0;
    flex-basis: 45%;
  }
`;
const MainContent = styled.div`
  @media (min-width: 992px) {
    display: flex;
    gap: 26px;
    flex-wrap: wrap;
  }
  > div:nth-child(2) {
    min-width: 180px;
    ul {
      > li {
        flex-basis: 100%;
      }
    }
  }
  > div:nth-child(3) {
    min-width: 200px;
    ul {
      > li {
        flex-basis: 100%;
      }
    }
  }
`;

const Country = styled.div`
  color: black;
  font-weight: 900;
  text-align: left;
`;

const Language = styled.a`
  font-size: 13px;
  color: #eb1700;
  line-height: 1;
  text-transform: uppercase;
  text-decoration: none !important;
  &:hover,
  &:focus,
  &:active {
    color: #eb1700;
  }
`;

const Divider = styled.span`
  color: black;
  font-size: 14px;
`;

const RegionContent = styled.div`
  @media (min-width: 992px) {
    display: flex;
    gap: 26px;
    > ul {
      flex-basis: 50%;
    }
  }
`;
const LanguageList = styled.div`
  display: flex;
  gap: 0 5px;
  align-items: center;
  flex-wrap: wrap;
`;

const Line = styled.div`
  border-bottom: 1px solid #ababab;
`;

function PrivacyPolicy() {
  const { t } = useTranslation();
  const padding = '16px 0px 16px 0px';

  // const regions = PRIVACY_POLICY_DATA.reduce((acc, curr) => {
  //   acc[curr.region] = [...(acc[curr.region] || []), curr];
  //   return acc;
  // }, {});
  let regions = PRIVACY_POLICY_DATA.reduce((acc, curr) => {
    acc[curr.region] = [...(acc[curr.region] || []), curr];
    return acc;
  }, {});

  const sortedRegions = Object.keys(regions).sort((a, b) => {
    if (a === 'Global & Asia Pacific') return -1;
    if (b === 'Global & Asia Pacific') return 1;
    return a.localeCompare(b);
  });

  function chunkArray(array, chunkSize) {
    let index = 0;
    let arrayLength = array.length;
    let tempArray = [];

    for (index = 0; index < arrayLength; index += chunkSize) {
      let chunk = array.slice(index, index + chunkSize);
      tempArray.push(chunk);
    }

    return tempArray;
  }

  return (
    <div className="legal-container">
      <Stack direction={'row'}>
        <Link to="/">
          <img
            src="images/ep_back.png"
            width={'40px'}
            height={'40px'}
            alt={t('termsOfUse.img')}
            style={{ paddingRight: '8px' }}
          />
        </Link>
        <Title title={t('footer.privacyPolicy')} titlePaddingStyle={padding}></Title>
      </Stack>
      <StyledBody>
        <MainContent>
          {sortedRegions
            .filter((region) => region !== 'Janssen Global')
            .map((region) => {
              const countries = regions[region];
              const countryChunks = chunkArray(countries, 11);
              const golbalChunks = chunkArray(regions['Janssen Global'], 11);

              return (
                <div key={region}>
                  <>
                    {region === 'Asia Pacific' && (
                      <>
                        <CustomH2>Global</CustomH2>
                        <RegionContent>
                          {golbalChunks.map((chunk, index) => (
                            <CustomList key={index}>
                              {chunk.map((country) => (
                                <>
                                  <li key={country.country}>
                                    <Country>{country.country}</Country>
                                    <LanguageList>
                                      {country.languages.map((language, index) => (
                                        <>
                                          <Language
                                            key={language.language}
                                            href={language.link}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {language.language}
                                          </Language>
                                          {index < country.languages.length - 1 && <Divider> | </Divider>}
                                        </>
                                      ))}
                                    </LanguageList>
                                  </li>
                                  {country?.isRegional && <Line />}
                                </>
                              ))}
                            </CustomList>
                          ))}
                        </RegionContent>
                      </>
                    )}
                    <CustomH2>{region}</CustomH2>
                    <RegionContent>
                      {countryChunks.map((chunk, index) => (
                        <CustomList key={index}>
                          {chunk.map((country) => (
                            <>
                              <li key={country.country}>
                                <Country>{country.country}</Country>
                                <LanguageList>
                                  {country.languages.map((language, index) => (
                                    <>
                                      <Language
                                        key={language.language}
                                        href={language.link}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {language.language}
                                      </Language>
                                      {index < country.languages.length - 1 && <Divider> | </Divider>}
                                    </>
                                  ))}
                                </LanguageList>
                              </li>
                              {country?.isRegional && <Line />}
                            </>
                          ))}
                        </CustomList>
                      ))}
                    </RegionContent>
                  </>
                </div>
              );
            })}
        </MainContent>
      </StyledBody>
    </div>
  );
}
export default PrivacyPolicy;
