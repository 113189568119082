import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../fonts.css';
import Warning from './reusableComponents/Warning';
import { environment } from '../environments/environment';

function Header({ showSVG }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const {t} = useTranslation();
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setWindowWidth(window.innerWidth);
    };
    //This line adds an event listener to the resize event of the window
    window.addEventListener('resize', handleResize);
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {isMobile ? (
        <>
          {!environment.production && <Warning />}
          <Box
            sx={{
              maxWidth: `${windowWidth}`,
              height: '48px',
              bgcolor: '#D71500',
            }}
          ></Box>
          <Box
            sx={{
              maxWidth: `${windowWidth}`,
              padding: '13px',
            }}
          >
            {showSVG && (
              <Link to="/" style={{ position: 'absolute' }}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="32" height="32" rx="4" fill="#F1EFED" />
                  <path
                    d="M16 9.69L21 14.19V22H19V16H13V22H11V14.19L16 9.69ZM16 7L6 16H9V24H15V18H17V24H23V16H26L16 7Z"
                    fill="#EB1700"
                  />
                </svg>
              </Link>
            )}
            <Link to="/" style={{ display: 'flex', justifyContent: 'center' }}>
              <img src="logos/JJ_Logo_SingleLine_Red_RGB.png" width={'171.94'} height={'34px'} />
            </Link>
          </Box>
        </>
      ) : (
        <>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {!environment.production && <Warning />}
            <div className="topbar">
              <Link to={'/'}>
                <img src="logos/JJ_Logo_SingleLine_Red_RGB.png" width={'257.91px'} height={'50px'} />
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default Header;
