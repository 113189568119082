import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

const CustomDiv = styled.div`
font-family: 'JohnsonText-Regular', sans-serif;
`;

const styles = {
overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
},
alert: {
    background: 'white',
    color:'black',
    padding: '20px',
    borderRadius: '5px',
    textAlign: 'center',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    width: '400px',
    height:'90px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems:'center'
},
};

const customButton = {
backgroundColor: '#E8E6E3',
color: 'black',
borderRadius: '8px',
gap: '10px',
width: '80px',
height: '40px',
border: 'none',
fontFamily: 'JohnsonText-Regular, sans-serif',
fontWeight: '700',
fontSize: '13px',
lineHeight: '16px',
};


function CustomAlert ({ message, onClose }){
    const { t } = useTranslation();
    return(
        <div style={styles.overlay}>
            <div style={styles.alert}>
                <CustomDiv>{message}</CustomDiv>
                <button style={customButton} onClick={onClose}>{t('main.GTINSearch.ok')}</button>
            </div>
        </div>
    )
}

export default CustomAlert;