// usePageTracking.js
import { useEffect } from 'react';

const usePageTracking = () => {
    const location = window.location;

    useEffect(() => {
        const logPageView = async () => {
            fetch('/save_activity_data/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    activity_type: 'page_view',
                    additional_data: {
                        page: location.hash.indexOf('#/search') === 0 ? 'search' : 'home',
                        query: location.search ? location.search.substring(1) : null,
                        hash: location.hash,
                    },
                }),
            });
        };

        logPageView();
    }, [location]);
};

export default usePageTracking;
