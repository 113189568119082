import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Title from '../Title';
import { Link } from 'react-router-dom';
import '../Home.css';
import '../../../fonts.css';
import styled from '@emotion/styled';
const StyledBody = styled.p`
  font-family: 'JohnsonText-Regular', sans-serif;
  color: black;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: justify;
`;
function LegalNotice() {
  const [siteAddress, setSiteAddress] = React.useState('');
  const { t } = useTranslation();
  React.useEffect(() => {
    let origin = window.location.origin;
    origin = origin.replace(/https?:\/\//, '');
    setSiteAddress(origin);
  }, []);
  const linkColor = 'var(--bs-link-color)';
  const applyLinkStyles = (htmlContent) => {
    return htmlContent.replace(
      /<a\s+(?:[^>]*?\s+)?href="([^"]*)"([^>]*)>(.*?)<\/a>/g,
      `<a style="color: ${linkColor}; text-decoration: underline;" href="$1"$2>$3</a>`,
    );
  };
  const padding = '16px 0px 16px 0px';
  return (
    <div className="legal-container">
      <Stack direction={'row'}>
        <Link to="/">
          <img
            src="images/ep_back.png"
            width={'40px'}
            height={'40px'}
            alt={t("termsOfUse.img")}
            style={{ paddingRight: '8px' }}
          />
        </Link>
        <Title title={t('legalNotice.title')} titlePaddingStyle={padding}></Title>
      </Stack>
      <StyledBody>
        <div
          dangerouslySetInnerHTML={{ __html: applyLinkStyles(t('legalNotice.content.paragraph1', { siteAddress })) }}
        />
        <br />
        {t('legalNotice.content.paragraph2')}
        <br />
        <br />
        {t('legalNotice.content.paragraph3')}
        <br />
        <br />
        {t('legalNotice.content.paragraph4')}
        <br />
        <br />
        {t('legalNotice.content.paragraph5')}
        <br />
        <br />
        {t('legalNotice.content.paragraph6')}
        <br />
        <br />
        {t('legalNotice.content.paragraph7')}
        <br />
        <br />
        {t('legalNotice.content.address.title')}
        <br />
        <br />
        {t('legalNotice.content.address.addressLine1')}
        <br />
        {t('legalNotice.content.address.addressLine2')}
        <br />
        {t('legalNotice.content.address.addressLine3')}
        <br />
        {t('legalNotice.content.address.addressLine4')}
        <br />
        {t('legalNotice.content.address.addressLine5')}
        <br />
        <br />
      </StyledBody>
    </div>
  );
}
export default LegalNotice;
