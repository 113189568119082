import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Scan from './ScanComponent';
import '../Home.css';
import Search from './SearchComponent';
import styled from '@emotion/styled';
const CustomH2 = styled.h2`
  font-family: 'JohnsonDisplay-Regular', sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #eb1700;
  padding: 0 5px;
  text-align: center;
`;
function ScanSearch({ isMobile }) {
  const { t } = useTranslation();
  return (
    <Stack
      direction={{ xs: 'column', sm: 'column', md: 'row' }}
      alignItems="center"
      justifyContent={'space-between'}
      sx={{ padding: isMobile ? '8px 0' : '24px 0px', gap: isMobile && '8px' }}
    >
      <Scan isMobile={isMobile} />
      {!isMobile && <CustomH2>{t('main.or')}</CustomH2>}
      <Search isMobile={isMobile} />
    </Stack>
  );
}
export default ScanSearch;
